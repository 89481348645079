<template>
  <div>
    <el-form class="customize-search-form" inline>
      <el-form-item>
        <DoctorSearch
          inputholder="医生姓名"
          :job-title-list="jobTitleList"
          :ling-gong-sign-status-list="lingGongSignStatusList"
          :labor-sign-status-list="laborSignStatusList"
          :show-org-list="false"
          @hospital="searchChange"
        />
      </el-form-item>
      <el-form-item class="last-box">
        <!-- <el-button
          type="primary"
          plain
          @click="onShowInvite"
        >
          邀请医生成为播客
        </el-button> -->
        <el-popover
          v-model="showExportPop"
          width="200"
          trigger="manual"
          placement="left"
        >
          <div class="lay-box">是否导出数据?</div>
          <div style="text-align: right;">
            <el-button
              size="mini"
              type="text"
              @click="showExportPop = false"
            >
              取消
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="onExport"
            >
              确定
            </el-button>
          </div>
          <el-button
            slot="reference"
            type="text"
            style="margin-left: 10px;"
            @click="onShowExportModal"
          >
            导出数据
          </el-button>
        </el-popover>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        v-loading="loading"
        :data="speakerList"
        class="customize-el-table"
        stripe
      >
        <el-table-column
          prop="name"
          label="姓名"
          width="150"
          fixed="left"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name || "/" }}</span>
            <span
              v-if="scope.row.isCancel"
              style="color: #ff4921; padding-left: 3px;"
            >(已注销)</span>
            <span
              v-if="scope.row.isFrozen"
              style="color: #fb9c35; padding-left: 3px;"
            >(已冻结)</span>
          </template>
        </el-table-column>
        <el-table-column width="150" align="center" label="所属职称">
          <template slot-scope="scope">
            <span>{{ scope.row.titleName || "—" }}</span>
          </template>
        </el-table-column>
        <el-table-column width="150" align="center" label="所在科室">
          <template slot-scope="scope">
            <span>{{ scope.row.department || "—" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="hospital"
          align="center"
          show-overflow-tooltip
          label="医院"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.hospital || "—" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          align="center"
          width="120"
          label="编码"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.code || "—" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="representName"
          align="center"
          width="100"
          label="服务代表"
        >
          <template slot-scope="scope">
            <div
              v-for="v in scope.row.representItemList"
              :key="v.id"
              @mouseenter="onMouseEnter(v.id, scope.row.id)"
            >
              <el-tooltip placement="right">
                <div slot="content">所在组织：{{ content }}</div>
                <div style="cursor: pointer;">{{ v.name }}</div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="灵活用工签约状态">
          <template slot-scope="scope">
            <span>{{ scope.row.lingGongSignStatus | statusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="平台劳务签约状态">
          <template slot-scope="scope">
            <span>{{ scope.row.laborSignStatus | statusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="200"
          fixed="right"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="onJumpChangeCode(scope.row)"
            >
              编辑编码
            </el-button>
            <el-button
              type="text"
              @click="onJumpDetail(scope.row.id)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="customize-el-pagination"
        background
        :page-sizes="[10, 20, 50, 100]"
        layout="total,sizes, prev, pager, next, jumper"
        :page-size="pageSize"
        :current-page="pageNo"
        :total="totalCount"
        @current-change="onChange"
        @size-change="handleSizeChange"
      />
    </div>
    <SpeakerInviteModal :visible="showInviteModal" @onClose="onClose" />
    <ChangeDoctorCode
      :dialog-form-visible="showChangeCode"
      :form="form"
      @onClose="onClose"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SpeakerInviteModal from '@/bizComponents/SpeakerInviteModal'
import DoctorSearch from '@/bizComponents/DoctorSearch.vue'
import ChangeDoctorCode from '@/bizComponents/ChangeDoctorCode'
export default {
  name: 'DoctorChildSpeaker',
  filters: {
    statusFilter(status) {
      switch (status) {
        case 1:
          return '已签约'
        default:
          return '未签约'
      }
    }
  },
  components: {
    SpeakerInviteModal,
    DoctorSearch,
    ChangeDoctorCode
  },
  data() {
    return {
      selectedDoctorId: '', // 选择的医生id
      selectedJobTitleId: '',
      selectedJobTitle: '',
      showInviteModal: false,
      selectedDepartmentId: '',
      showCertModal: false,
      showExportPop: false,
      searchText: '',
      pageNo: 1,
      pageSize: 20,
      totalCount: 0,
      loading: false,
      selectedSignUp: '',
      lingGongSignStatusList: [
        { title: '灵工签约状态', value: '' },
        { title: '灵工未签约', value: 0 },
        { title: '灵工已签约', value: 1 }
      ],
      laborSignStatusList: [
        { title: '劳务签约状态', value: '' },
        { title: '劳务未签约', value: 0 },
        { title: '劳务已签约', value: 1 }
      ],
      baseInfo: {},
      showChangeCode: false,
      form: {},
      content: ''
    }
  },
  computed: {
    ...mapGetters(['speakerList', 'jobTitleList', 'hospitalDepartmentList'])
  },
  mounted() {
    this.baseInfo = {}
    this.init()
    if (this.hospitalDepartmentList.length < 2) {
      this.getHospitalDepartmentList()
    }
    if (this.jobTitleList.length < 2) {
      this.getJobTitleList()
    }
  },
  methods: {
    ...mapActions([
      'getSpeakerList',
      'getJobTitleList',
      'getHospitalDepartmentList',
      'getRepresentOrgList',
      'getSpeakerListDown'
    ]),
    onMouseEnter(representId, doctorId) {
      const name = []
      this.getRepresentOrgList({
        representId: representId,
        doctorId: doctorId
      }).then(res => {
        res.data.map(v => {
          name.push(v.name)
        })
        this.content = name.toString()
        this.content = this.content.replace(/,/g, '/')
      })
    },
    searchChange(options) {
      this.pageNo = 1
      this.baseInfo = options
      this.init()
    },
    onShowCertModal(id = '') {
      this.showCertModal = true
      this.selectedDoctorId = id
    },
    onClose() {
      this.showCertModal = false
      this.showInviteModal = false
      this.showChangeCode = false
    },
    onShowInvite() {
      this.showInviteModal = true
    },
    onShowExportModal() {
      this.showExportPop = true
    },
    onExport() {
      if (!this.speakerList.length)
        return this.$message.error('暂无需要导出的数据')
      const data = Object.assign({}, this.baseInfo, {
        titleName: this.baseInfo.titleName,
        doctorName: this.baseInfo.searchKey,
        status: this.baseInfo.certifyStatus,
        hospitalName: this.baseInfo.hospitalName
      })
      this.getSpeakerListDown(data).then(
        res => {
          this.$message.success(res.data)
          this.showExportPop = false
        },
        rea => {
          this.$message.error(rea.message)
          this.showExportPop = false
        }
      )
    },
    onSelectJobTitle(id) {
      this.selectedJobTitleId = id
      if (id === '') {
        this.selectedJobTitle = ''
      } else {
        this.selectedJobTitle = [...this.jobTitleList].filter(
          v => v.id === id
        )[0].label
      }
      this.pageNo = 1
      this.init()
    },
    onSelectDepartment(v) {
      this.pageNo = 1
      this.selectedDepartmentId = v
      this.init()
    },
    onSelectSignUp(v) {
      this.pageNo = 1
      this.selectedSignUp = v
      this.init()
    },
    onJumpDetail(id = '') {
      this.$router.push(`/doctor/stat/${id}`)
    },
    onChange(pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.init()
    },
    onJumpChangeCode(form) {
      this.form = form
      this.showChangeCode = true
    },
    onSearch() {
      this.pageNo = 1
      this.selectedJobTitle = ''
      this.departmentId = ''
      this.selectedSignUp = ''
      this.init()
    },
    init() {
      this.loading = true
      const data = Object.assign({}, this.baseInfo, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        titleName: this.baseInfo.titleName,
        doctorName: this.baseInfo.searchKey,
        status: this.baseInfo.certifyStatus,
        speakerStatus: '',
        certifyStatus: ''
      })
      this.getSpeakerList(data).then(
        res => {
          this.loading = false
          this.totalCount = res.count
        },
        () => {
          this.loading = false
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
