<template>
  <div class="speaker-list">
    <div class="chart-wrap">
      <div v-loading="!showFunnel" class="chart-item">
        <el-row type="flex" justify="space-between">
          <div>
            <el-select
              v-model="productTagIds"
              clearable
              class="select"
              placeholder="所有产品"
            >
              <el-option
                v-for="item in productTagList"
                :key="item.productId"
                :label="item.productName"
                :value="item.productId"
              />
            </el-select>
            <span style="padding-left: 10px;"> 企业播客分级分布图</span>
          </div>
          <div class="text-button" @click="onJumpHelp">了解播客管理 ></div>
        </el-row>
        <EchartsFunnel v-if="showFunnel" :option="optionFunnel" />
      </div>
      <div v-loading="!showBar" class="chart-item">
        <el-row type="flex" justify="space-between">
          <div>
            <el-select
              v-model="productTagIds"
              clearable
              class="select"
              placeholder="所有产品"
            >
              <el-option
                v-for="item in productTagList"
                :key="item.productId"
                :label="item.productName"
                :value="item.productId"
              />
            </el-select>
            <span
              style="padding-left: 10px;"
            >直播会议类型分布图（近半年）</span>
          </div>
        </el-row>
        <EchartsBar v-if="showBar" :option="optionBar" />
      </div>
    </div>
    <el-card>
      <div class="flex-between">
        <el-radio-group
          v-model="doctorType"
          class="radio-switch"
          size="mini"
          @change="onSearch"
        >
          <el-radio-button label="1">所有播客</el-radio-button>
          <el-radio-button label="2">播客晋升</el-radio-button>
        </el-radio-group>
        <div>
          <el-button
            v-if="
              !batchProcessing &&
                $store.state.user.userInfo.isTopOrg &&
                doctorType === '2'
            "
            type="primary"
            plain
            @click="batchProcessing = true"
          >
            批量操作
          </el-button>
          <el-button
            v-if="batchProcessing"
            type="primary"
            plain
            @click="onBatchProcess('1')"
          >
            批量通过
          </el-button>
          <el-button
            v-if="batchProcessing"
            type="primary"
            plain
            @click="onBatchProcess('2')"
          >
            批量拒绝
          </el-button>
          <el-button
            v-if="batchProcessing"
            type="danger"
            plain
            @click="
              batchProcessing = false
              resetSelection()
            "
          >
            取消批量操作
          </el-button>
          <el-button type="text" @click="exportData">
            导出所有数据
          </el-button>
          <div class="dropdown-box" @mouseleave="onMouseleave">
            <el-button type="primary" @click="handleCommand">
              <i class="el-icon-plus el-icon--right" />
              新建播客
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <div
              v-show="isShowDropdown"
              slot="dropdown"
              class="dropdown-custom-item"
            >
              <div class="custom-item" @click="onCreateSpeaker('a')">
                新建播客
              </div>
              <div class="custom-item" @click="onCreateSpeaker('b')">
                批量新建
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-form inline :model="search" class="search-box">
        <el-form-item>
          <el-select
            v-model="search.productTagIds"
            clearable
            class="select"
            placeholder="所有产品"
          >
            <el-option
              v-for="item in productTagList"
              :key="item.productId"
              :label="item.productName"
              :value="item.productId"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="search.professionTitle"
            :options="jobProfessionList"
            :props="{
              checkStrictly: true,
              value: 'id',
              children: 'childrenList'
            }"
            collapse-tags
            collapse-tags-tooltip
            clearable
            placeholder="请选择职业/职称"
          />
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="search.oneTwoLevelDepartmentIds"
            :options="platformDepartmentList"
            :props="{ checkStrictly: true, value: 'id', children: 'childes' }"
            collapse-tags
            collapse-tags-tooltip
            clearable
            placeholder="请选择一级/二级科室"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="search.hospitalGradeId"
            placeholder="机构等级"
            class="select"
            clearable
          >
            <el-option
              v-for="item in hospitalLevelList"
              :key="item.id"
              :label="item.content"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="search.provinceId"
            placeholder="所有省"
            class="select"
            clearable
          >
            <el-option
              v-for="item in provinceList"
              :key="item.provinceId"
              :label="item.provinceName"
              :value="item.provinceId"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="search.provinceId">
          <el-select
            v-model="search.cityId"
            placeholder="所有市"
            class="select"
            clearable
          >
            <el-option
              v-for="item in provinceList.find(
                v => v.provinceId === search.provinceId
              ).cities"
              :key="item.cityId"
              :label="item.cityName"
              :value="item.cityId"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="search.searchKey"
            prefix-icon="el-icon-search"
            class="input"
            clearable
            placeholder="搜索客户或机构"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="width: 90px;"
            @click="
              onSearch()
              resetSelection()
            "
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          v-if="doctorType === '1'"
          :key="doctorType"
          v-loading="loading"
          :data="allSpeakerList"
          width="100%"
        >
          <template slot="empty">
            <div class="empty">
              <div>
                目前暂无播客，请前往<el-button
                  type="text"
                  @click="onCreateSpeaker('a')"
                >
                  新建 >
                </el-button>
              </div>
            </div>
          </template>
          <el-table-column label="客户姓名" prop="doctorName" />
          <el-table-column align="center" label="职业" prop="identity">
            <template slot-scope="scope">
              <div>{{ scope.row.identity | identityText }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="部门/科室" prop="department" />
          <el-table-column align="center" label="职称" prop="titleName" />
          <el-table-column
            align="center"
            label="机构名称"
            prop="hospital"
            show-overflow-tooltip
            width="200"
          />
          <el-table-column align="center" label="省/市" prop="hospitalRegion" />
          <el-table-column
            align="center"
            label="机构等级"
            prop="hospitalGrade"
          />
          <el-table-column
            align="center"
            label="播客认证状态"
            prop="speakerStatus"
            width="100"
          >
            <template slot-scope="scope">
              <div
                :style="
                  `color: ${getSpeakerStatusColor(
                    scope.row.speakerStatus
                  )}`
                "
              >
                {{
                  speakerStatusText(
                    scope.row.speakerStatus
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="灵活用工签约状态"
            prop="speakerStatus"
            width="70"
          >
            <template slot-scope="scope">
              <div
                :style="
                  `color: ${statusColor(
                    lingGongSignStatusObj,
                    scope.row.lingGongSignStatus
                  )}`
                "
              >
                {{
                  statusText(
                    lingGongSignStatusObj,
                    scope.row.lingGongSignStatus
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="平台劳务签约状态"
            prop="speakerStatus"
            width="70"
          >
            <template slot-scope="scope">
              <div
                :style="
                  `color: ${statusColor(
                    laborSignStatusObj,
                    scope.row.laborSignStatus
                  )}`
                "
              >
                {{
                  statusText(
                    laborSignStatusObj,
                    scope.row.laborSignStatus
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="客户编码"
            prop="doctorCode"
            show-overflow-tooltip
          />
          <el-table-column align="center" label="产品" prop="productTagName" />
          <el-table-column
            align="center"
            label="播客企业分级"
            prop="orgGrade"
          />
          <el-table-column
            align="center"
            label="播客平台级别"
            prop="platformGrade"
          />
          <el-table-column
            label="操作"
            prop=""
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                :disabled="!scope.row.canEdit"
                type="text"
                @click="onOperateSpeaker(scope.row.id, '1')"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                style="color: #e8312f;"
                @click="onOperateSpeaker(scope.row.id, '3')"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-if="doctorType === '2'"
          :key="doctorType"
          ref="multipleTable"
          v-loading="loading"
          :data="gradeSpeakerList"
          width="100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column v-if="batchProcessing" type="selection" width="55" />
          <el-table-column label="客户姓名" prop="doctorName" />
          <el-table-column align="center" label="部门/科室" prop="department" />
          <el-table-column align="center" label="职称" prop="titleName" />
          <el-table-column
            align="center"
            label="机构名称"
            prop="hospital"
            width="150"
            show-overflow-tooltip
          />
          <el-table-column align="center" label="产品" prop="productTagName" />
          <el-table-column align="center" label="提交时间" prop="submitTime" />
          <el-table-column
            align="center"
            label="原播客企业分级"
            width="250"
            prop="orgGrade"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.orgGrade || '/' }}
                <span
                  v-if="scope.row.orgGradeAuditStatus !== null"
                  :style="
                    `color: ${getSpeakerStatusColor(
                      scope.row.orgGradeAuditStatus
                    )}`
                  "
                >
                  （{{ promotionStatusText(scope.row.orgGradeAuditStatus) }}）
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="播客平台级别"
            width="250"
            prop="platformGrade"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.platformGrade || '/' }}
                <span
                  v-if="scope.row.platformGradeAuditStatus !== null"
                  :style="
                    `color: ${getSpeakerStatusColor(
                      scope.row.platformGradeAuditStatus
                    )}`
                  "
                >
                  （{{
                    promotionStatusText(scope.row.platformGradeAuditStatus)
                  }}）
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            prop=""
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                v-if="
                  $store.state.user.userInfo.isTopOrg &&
                    scope.row.orgGradeAuditStatus === 0
                "
                :disabled="scope.row.canEdit"
                type="text"
                @click="onOperateSpeakerPromotion(scope.row.id, '1')"
              >
                审核
              </el-button>
              <el-button
                type="text"
                @click="onOperateSpeakerPromotion(scope.row.id, '2')"
              >
                查看
              </el-button>
              <el-button
                v-if="scope.row.orgGradeAuditStatus === 0"
                type="text"
                style="color: #e8312f;"
                @click="onOperateSpeakerPromotion(scope.row.id, '3')"
              >
                取消申请
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="pagination.total"
          :current-page="pagination.pageNo"
          :page-size="10"
          background
          class="customize-el-pagination pagination"
          layout="total, prev, pager, next, jumper"
          @current-change="onPageChange"
        />
      </div>
    </el-card>
    <!-- 弹窗 -->
    <DoctorSelect
      :visible.sync="isShowSelectPop"
      :job-list="jobProfessionList"
      @onConfirm="onConfirmSelect"
    />
    <DoctorLvEdit
      :id="checkId"
      :visible.sync="isShowEditPop"
      :is-create="isCreate"
      @onConfirm="getDataList"
    />
    <DoctorLvCheck
      :id="checkId"
      :is-look="isLook"
      :visible.sync="isShowCheckPop"
      @onConfirm="getDataList"
    />
    <CommonImportExcelDialog
      v-model="isShowImport"
      :upload-api="$API.uploadBatchImportOrgGradeSpeakerModel"
      :down-type="5"
      :confirm-api="$API.confirmBatchImportOrgGradeSpeakerModel"
      @onComplete="onComplete"
    />
  </div>
</template>

<script>
import EchartsFunnel from '@/baseComponents/EchartsFunnel.vue'
import EchartsBar from '@/baseComponents/EchartsBar.vue'
import DoctorLvEdit from '@/bizComponents/DoctorLvEdit'
import DoctorLvCheck from '@/bizComponents/DoctorLvCheck'
import DoctorSelect from '@/bizComponents/DoctorSelect'
import CommonImportExcelDialog from '@/baseComponents/CommonImportExcelDialog'

export default {
  name: 'DoctorSpeakerManage',
  components: {
    EchartsFunnel,
    EchartsBar,
    DoctorLvEdit,
    DoctorSelect,
    DoctorLvCheck,
    CommonImportExcelDialog
  },
  filters: {
    identityText(identity) {
      switch (identity) {
        case 1:
          return '医生'
        case 2:
          return '店员'
        case 3:
          return '护士'
        case 4:
          return '药师'
        case 5:
          return '技师'
        default:
          return ''
      }
    }
  },
  data() {
    return {
      isShowImport: false,
      isLook: true,
      checkId: '',
      isCreate: true,
      isShowCheckPop: false,
      isShowEditPop: false,
      isShowSelectPop: false,
      selectDoctorId: '',
      batchProcessing: false,
      search: {
        productTagIds: '',
        identity: '',
        titleId: '',
        hospitalGradeId: '',
        provinceId: '',
        cityId: '',
        searchKey: '',
        oneTwoLevelDepartmentIds: [],
        professionTitle: [],
        oneLevelDepartmentId: '',
        twoLevelDepartmentId: ''
      },
      doctorType: '1',
      identityList: [],
      provinceList: [],
      hospitalLevelList: [],
      productTagList: [],
      allSpeakerList: [],
      gradeSpeakerList: [],
      jobProfessionList: [],
      platformDepartmentList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      showFunnel: false,
      showBar: false,
      optionFunnel: {},
      optionBar: {},
      multipleSelection: [],
      funnelData: [],
      barData: [],
      productTagIds: '',
      isShowDropdown: false,
      lingGongSignStatusObj: {
        0: {
          color: '#FF8500',
          text: '未签约'
        },
        1: {
          color: '#30C691',
          text: '已签约'
        }
      },
      laborSignStatusObj: {
        0: {
          color: '#FF8500',
          text: '未签约'
        },
        1: {
          color: '#30C691',
          text: '已签约'
        }
      }
    }
  },
  computed: {
    statusText() {
      return (obj, status) => {
        return obj[status]?.text || '/'
      }
    },
    statusColor() {
      return (obj, status) => {
        return obj[status]?.color || ''
      }
    },
    speakerStatusText() {
      return status => {
        if (status === -1) {
          return '未认证'
        } else if (status === 0) {
          return '认证中'
        } else if (status === 2) {
          return '认证失败'
        } else if (status === 1) {
          return '已认证'
        } else {
          return '/'
        }
      }
    },
    getSpeakerStatusColor() {
      return status => {
        if (status === -1) {
          return ''
        } else if (status === 0) {
          return '#FF8500'
        } else if (status === 2) {
          return '#E8312F'
        } else if (status === 1) {
          return '#30C691'
        } else {
          return ''
        }
      }
    },
    promotionStatusText() {
      return status => {
        if (status === 0) {
          return '审核中'
        } else if (status === 1) {
          return '审核通过'
        } else if (status === 2) {
          return '审核失败'
        } else if (status === 3) {
          return '取消申请'
        } else {
          return '/'
        }
      }
    }
  },
  watch: {
    productTagIds(val) {
      const proIds = val ? [val] : []
      this.getFunnelData(proIds)
      this.getBarData(proIds)
    }
  },
  mounted() {
    this.getProductTagList()
    this.getProvince()
    this.getHospitalLevel()
    this.getJobProfessionList()
    if (this.platformDepartmentList.length < 2) {
      this.getPlatformDepartmentList()
    }
    this.getDataList()
    this.getFunnelData()
    this.getBarData()
  },
  methods: {
    onConfirmSelect(val) {
      if (!val) {
        this.$message.error('请选择播客')
        return
      }
      this.checkId = val
      this.isCreate = true
      this.selectDoctorId = val
      this.isShowSelectPop = false
      this.isShowEditPop = true
    },
    // 获取省市
    async getProvince() {
      try {
        const res = await this.$axios.get(this.$API.getProvince)
        this.provinceList = res.data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    // 获取平台科室
    getPlatformDepartmentList() {
      this.$axios.get(this.$API.platformDepartment).then(
        res => {
          this.platformDepartmentList = res.data
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    // 获取医院等级
    async getHospitalLevel() {
      try {
        const res = await this.$axios.get(this.$API.getHospitalLevel)
        this.hospitalLevelList = res.data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    // 获取医生职业接口
    getJobProfessionList() {
      this.$axios.get(this.$API.jobTitles).then(
        res => {
          this.jobProfessionList = res.data
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    // 获取产品标签
    async getProductTagList() {
      try {
        const params = {
          clientGradeId: '',
          tagGroupId: 'e887acc95f1ed1747f3158cd090cbdb6'
        }
        const { data } = await this.$axios.get(this.$API.productTagList, {
          params
        })
        this.productTagList = data
      } catch (error) {
        console.log(error)
      }
    },
    // 获取漏斗图数据
    async getFunnelData(proIds) {
      this.showFunnel = false
      const funnelData = []
      const obj = {}
      try {
        const res = await this.$axios.post(
          this.$API.getGradeDistributionInfo,
          proIds
        )
        const funnelColor = [
          '#FF0A15',
          '#FF7D00',
          '#FFCC00',
          '#72DB00',
          '#00AFFF',
          '#3D61E3'
        ]
        // 对数据按照gradeId从大到小进行排序,然后取出gradeName
        const group = res.data
          .sort((a, b) => b.gradeId - a.gradeId)
          .map(item => item.gradeName)
        const size = [20, 30, 40, 50, 80, 110]
        res.data.forEach((item, index) => {
          obj[item.gradeName] = item.count
          group.forEach((v, i) => {
            if (item.gradeName === v) {
              funnelData.push({
                value: size[i],
                name: item.gradeName,
                itemStyle: { color: funnelColor[index] }
              })
            }
          })
        })
        this.optionFunnel = {
          tooltip: {
            trigger: 'item',
            formatter: function(params) {
              return `${params.name}：${obj[params.name]}`
            }
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            icon: 'circle',
            top: '10',
            data: group
          },
          series: [
            {
              name: '播客分级',
              type: 'funnel',
              width: '60%',
              height: '80%',
              left: '20%',
              top: '5%',
              sort: 'ascending',
              label: {
                show: true,
                position: 'inside',
                formatter: function(params) {
                  return obj[params.name]
                }
              },
              data: funnelData
            }
          ]
        }
        this.showFunnel = true
      } catch (error) {
        this.showFunnel = true
        console.log('error: ', error)
      }
    },
    // 获取柱状图数据
    async getBarData(proIds) {
      this.showBar = false
      try {
        const res = await this.$axios.post(
          this.$API.getGradeLiveDistributionInfo,
          proIds
        )
        const barColor = [
          '#E1E1E1',
          '#BBC8F5',
          '#8198ED',
          '#5A78E7',
          '#3D61E3',
          '#1342EE'
        ]
        const source = res.data
        // yAxisData、xAxisData 通过id排序
        source.sort((a, b) => a.gradeId - b.gradeId)
        const yAxisData = source.map(item => item.gradeName)
        // 对source里的数据进行排序，然后取出typeName，赋值给xAxisData
        const xAxisData = source[0].liveGradeAndCountVOS
          .sort((a, b) => a.typeId - b.typeId)
          .map(item => item.typeName)
        const nullData = source.map(item =>
          item.liveGradeAndCountVOS.every(v => v.count === 0)
        )
        const series = xAxisData.map((xItem, xIndex) => {
          const obj = {
            name: xItem,
            type: 'bar',
            stack: '总量',
            itemStyle: {
              color: barColor[xIndex]
            },
            data: yAxisData.map(yItem => {
              const item = source.find(v => v.gradeName === yItem)
              const data = item.liveGradeAndCountVOS.find(
                v => v.typeName === xItem
              )
              return nullData.every(v => v) ? 0.1 : data ? data.count : 0
            })
          }
          return obj
        })

        this.optionBar = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line'
            },
            formatter: function(params) {
              const arr = params.map(
                item =>
                  `${item.seriesName}：${item.value === 0.1 ? 0 : item.value}`
              )
              return `${params[0].axisValue}<br />${arr.join('<br />')}`
            },
            position: function(pt) {
              return [pt[0] + 10, pt[1] + 10]
            }
          },
          legend: {
            orient: 'vertical',
            right: 'right',
            icon: 'circle',
            top: '10'
          },
          grid: {
            left: '1%',
            right: '20%',
            bottom: '8%',
            top: '5%',
            containLabel: true
          },
          xAxis: {
            show: false,
            type: 'value'
          },
          yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: yAxisData
          },
          series: series
        }
        this.showBar = true
      } catch (error) {
        this.showBar = true
        console.log('error: ', error)
      }
    },
    onMouseenter() {
      this.isShowDropdown = true
    },
    onMouseleave() {
      this.isShowDropdown = false
    },
    handleCommand() {
      this.isShowDropdown = !this.isShowDropdown
    },
    onSearch(type) {
      if (this.loading) return
      this.pagination.pageNo = 1
      this.pagination.total = 0
      this.allSpeakerList = []
      this.gradeSpeakerList = []
      this.doctorType = type || this.doctorType
      this.resetSelection()
      this.getDataList()
    },
    getDataList() {
      if (this.doctorType === '1') {
        this.batchProcessing = false
        this.onGetGradeSpeakerList()
      } else if (this.doctorType === '2') {
        this.onGetGradeSpeakerPromotionList()
      }
    },
    onComplete() {
      if (this.doctorType === '2') {
        this.onGetGradeSpeakerPromotionList()
      }
    },
    getParams() {
      const params = { ...this.search }
      if (params.professionTitle && params.professionTitle.length) {
        params.identity = params.professionTitle[0]
        params.titleId = params.professionTitle[1]
      }
      if (params.oneTwoLevelDepartmentIds.length) {
        params.oneLevelDepartmentId = params.oneTwoLevelDepartmentIds[0]
        params.twoLevelDepartmentId = params.oneTwoLevelDepartmentIds[1]
      }
      if (this.search.productTagIds) {
        params.productTagIds = [this.search.productTagIds]
      } else {
        params.productTagIds = []
      }
      delete params.professionTitle
      delete params.oneTwoLevelDepartmentIds

      return params
    },
    // 获取所有播客
    async onGetGradeSpeakerList() {
      if (this.loading) return
      try {
        const searchValue = this.getParams()
        const req = {
          ...searchValue,
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize
        }
        this.loading = true
        const {
          data: { data, totalCount }
        } = await this.$axios.post(this.$API.getGradeSpeakerList, req)
        this.allSpeakerList = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 获取晋升播客
    async onGetGradeSpeakerPromotionList() {
      if (this.loading) return
      try {
        const searchValue = this.getParams()
        const req = {
          ...searchValue,
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize
        }
        this.loading = true
        const {
          data: { data, totalCount }
        } = await this.$axios.post(this.$API.getGradeSpeakerPromotionList, req)
        this.gradeSpeakerList = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    onPageChange(pageNo) {
      this.pagination.pageNo = pageNo
      this.getDataList()
    },
    resetSelection() {
      if (this.doctorType === '2') {
        this.multipleSelection = []
        this.$refs.multipleTable.clearSelection()
      }
    },
    exportData() {
      if (this.doctorType === '1') {
        if (!this.allSpeakerList.length) {
          return this.$message.error('暂无数据可导出')
        }
      } else if (this.doctorType === '2') {
        if (!this.gradeSpeakerList.length) {
          return this.$message.error('暂无数据可导出')
        }
      }
      this.$confirm('确定导出所有数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.exportSpeakerData()
        })
        .catch(() => {})
    },
    async exportSpeakerData() {
      try {
        const searchValue = this.getParams()
        const req = {
          ...searchValue,
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize
        }
        const api =
          this.doctorType === '1'
            ? this.$API.exportGradeSpeaker
            : this.$API.exportGradeSpeakerPromotion
        const res = await this.$axios.post(api, req)
        this.$message.success(res.data)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onOperateSpeaker(id, type) {
      this.checkId = id
      if (type === '1') {
        this.isCreate = false
        this.isShowEditPop = true
      } else if (type === '3') {
        this.onDelRaging(id)
      }
    },
    async onDelRaging(id) {
      try {
        await this.$MessageDialog({
          message:
            '<div style="margin: 30px 0px;">请确认是否删除该分级播客？</div>',
          title: '提示',
          closeOnClickModal: true
        })
        await this.$axios.post(this.$API.deleteGradeSpeaker, null, {
          params: { id }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.onGetGradeSpeakerList()
      } catch (error) {
        if (error.code) {
          this.$message.error(error.message)
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onBatchProcess(status) {
      if (this.multipleSelection.length === 0) {
        return this.$message.error('请先选择播客')
      }
      const message =
        status === '1'
          ? `请确认是否批量同意该批${this.multipleSelection.length}名播客的企业级别晋级`
          : `请确认是否批量拒绝该批${this.multipleSelection.length}名播客的企业级别晋级`
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.batchProcess(status)
        })
        .catch(() => {})
    },
    async batchProcess(status) {
      try {
        const ids = this.multipleSelection.map(item => item.id)
        const res = await this.$axios.post(
          this.$API.batchProcessGradeSpeaker,
          ids,
          {
            params: { status }
          }
        )
        if (res.data) {
          this.$message.success('操作成功')
          this.resetSelection()
          this.onGetGradeSpeakerPromotionList()
        } else {
          this.$message.error('该批播客的企业分级晋级申请已被处理，请重新选择')
        }
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onOperateSpeakerPromotion(id, type) {
      this.checkId = id
      if (type === '1') {
        this.isLook = false
        this.isShowCheckPop = true
      } else if (type === '2') {
        this.isLook = true
        this.isShowCheckPop = true
      } else if (type === '3') {
        this.onCancelApply(id)
      }
    },
    async onCancelApply(id) {
      try {
        await this.$MessageDialog({
          message:
            '<div style="margin: 30px 0px;">请确认是否取消该播客的企业级别晋级申请？</div>',
          title: '提示',
          closeOnClickModal: true
        })
        await this.$axios.post(this.$API.cancelGradeSpeakerPromotion, null, {
          params: { id }
        })
        this.$message({
          type: 'success',
          message: '取消成功'
        })
        this.onGetGradeSpeakerPromotionList()
      } catch (error) {
        if (error.code) {
          this.$message.error(error.message)
        }
      }
    },
    onJumpHelp() {
      const url = this.$router.resolve('/help/detail/353174153071432095')
      window.open(url.href, '_blank')
    },
    onCreateSpeaker(command) {
      if (command === 'a') {
        this.isShowSelectPop = true
      } else if (command === 'b') {
        this.isShowImport = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.speaker-list {
  .search-box {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .el-form-item {
      display: inline-flex;
      flex: 0 1 auto;
      margin-bottom: 10px !important;
    }
  }
  .select {
    max-width: 120px;
  }
  .input {
    width: 160px;
    ::v-deep .el-input__inner {
      border-radius: 30px;
    }
  }
  .chart-wrap {
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
    gap: 10px;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .chart-item {
      padding: 10px;
      border: 1px solid #f5f5f5;
      height: 220px;
    }
    .text-button {
      color: #3d61e3;
      cursor: pointer;
    }
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .radio-switch {
    .el-radio-button:first-child .el-radio-button__inner {
      border-radius: 2px 0 0 2px;
    }
    .el-radio-button:last-child .el-radio-button__inner {
      border-radius: 0 2px 2px 0;
    }
  }
  .pagination {
    margin-top: 20px;
  }
  .dropdown-box {
    display: inline-block;
    position: relative;
    margin-left: 10px;
  }
  .dropdown-custom-item {
    position: absolute;
    left: 0;
    z-index: 30;
    width: 100%;
    background: #fbfbfb;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1px;
    border: 1px solid #e1e1e1;
  }
  .custom-item {
    height: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }
  .custom-item:hover {
    background: rgba(61, 97, 227, 0.12);
    color: #3d61e3;
  }
}
</style>
