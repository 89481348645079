<template>
  <el-row type="flex" justify="space-between" align="middle" :gutter="10">
    <el-col :span="8">
      <el-input clearable :placeholder="inputholder" class="search-input" v-model.trim="baseInfo.searchKey">
        <el-button slot="append" icon="el-icon-search" type="primary" round @click="onSearch"></el-button>
      </el-input>
    </el-col>
    <el-col :span="5">
      <el-select
        clearable
        :value='baseInfo.jobTitleId'
        @change='onSelectJobTitle'
      >
        <el-option
          v-for="item in jobTitleList"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
          {{ item.label }}
        </el-option>
      </el-select>

    </el-col>
    <el-col :span="5">
      <el-select
        clearable
        :value='baseInfo.lingGongSignStatus'
        @change='onSelectCertify($event, "lingGongSignStatus")'>
        <el-option
          v-for="item in lingGongSignStatusList"
          :key="item.value"
          :label="item.title"
          :value="item.value"
        />
      </el-select>
    </el-col>
    <el-col :span="5">
      <el-select
        clearable
        :value='baseInfo.laborSignStatus'
        @change='onSelectCertify($event, "laborSignStatus")'>
        <el-option
          v-for="item in laborSignStatusList"
          :key="item.value"
          :label="item.title"
          :value="item.value"
        />
      </el-select>
    </el-col>
    <el-col :span="5">
      <el-cascader
        v-if="showOrgList"
          :options="orgList"
          :props="defaultProps"
          :show-all-levels="false"
          placeholder="所属组织"
          @change="onSelectOrg"
          clearable
      ></el-cascader>
    </el-col>
    <el-col :span="5" v-if="showOrgList">
      <el-select
        :value='baseInfo.representDoctorStatus'
        @change='onSelectBindStatus'>
        <el-option
          v-for="item in bindStatusList"
          :key="item.value"
          :label="item.title"
          :value="item.value"
        />
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DoctorSearch',
  props: {
    showOrgList: {
      type: Boolean,
      default: true
    },
    jobTitleList: {
      type: Array,
      default:function() {
        return []
      }
    },
    lingGongSignStatusList: {
      type: Array,
      default:function() {
        return []
      }
    },
    laborSignStatusList: {
      type: Array,
      default:function() {
        return []
      }
    },
    bindStatusList: {
      type: Array,
      default:function() {
        return []
      }
    },
    orgList: {
      type: Array,
      default:function() {
        return []
      }
    },
    inputholder: {
      type: String,
      default: '搜索医生姓名或医院名称'
    }
  },
  data() {
    return {
      baseInfo: {
        searchKey:"",
        jobTitleId: '',
        titleName: '',
        lingGongSignStatus: '',
        laborSignStatus: '',
        orgId:'',
        representDoctorStatus: '1'
      },
      firstBaseDepartmentList: [],
        defaultProps: {
            children: 'child',
            label: 'orgName',
            value:'orgId',
            disabled: 'isSelect',
            checkStrictly: true
        },
        orgId:''
    }
  },
  computed: {
    secondBaseDepartmentList() {
        const list = [...this.firstBaseDepartmentList].filter(v => this.baseInfo.oneLevelDepartmentId === v.id)
        return list.length && list[0].child || []
    },
    thirdBaseDepartmentList() {
        const list = [...this.secondBaseDepartmentList].filter(v => this.baseInfo.twoLevelDepartmentId === v.id)
        return list.length && list[0].child || []
    },
  },
  methods:{
    ...mapActions(['departmentQuery']),
    onSearch() {
      this.$emit("hospital",this.baseInfo)
    },
    clearHandleBaseSelect() {
        this.baseInfo = Object.assign({}, this.baseInfo, {
            hospitalName: '',
            hospitalId: '',
            oneLevelDepartmentName: '',
            twoLevelDepartmentName: '',
            threeLevelDepartmentName: '',
            oneLevelDepartmentId: '',
            twoLevelDepartmentId: '',
            threeLevelDepartmentId: '',
        })
        this.onBaseDepartmentList()
        this.$emit("hospital",this.baseInfo)
    },
    handleBaseSelect(item) {

        this.baseInfo = Object.assign({}, this.baseInfo, {
            hospitalName: item.value || item.hospitalName,
            hospitalId: item.id || item.hospitalId,
            oneLevelDepartmentName: item.oneLevelDepartmentName,
            twoLevelDepartmentName: item.twoLevelDepartmentName,
            threeLevelDepartmentName: item.threeLevelDepartmentName,
            oneLevelDepartmentId: item.oneLevelDepartmentId,
            twoLevelDepartmentId: item.twoLevelDepartmentId,
            threeLevelDepartmentId: item.threeLevelDepartmentId,
        })
        this.onBaseDepartmentList(item.id || item.hospitalId)
        this.$emit("hospital",this.baseInfo)
    },
    onBaseDepartmentList(id) {
        if(!id) {
          this.firstBaseDepartmentList = []
          return
        }
        this.departmentQuery({hospitalId: id}).then(res => {
            this.$nextTick(() => {
                this.firstBaseDepartmentList = res
            })
        }, rea => {
            this.$message.error(rea.message)
        })
    },
    getIdName(id, list,mapKey = 'name') {
        let name = ''
        if(id === '') {
          return name
        }
        for (const item of list) {
            if (item.id === id) {
                name = item[mapKey]
                break
            }
        }
        return name
    },
    getDepartmentListChildren(parentId) {
        this.baseInfo = Object.assign({}, this.baseInfo, {
            oneLevelDepartmentName: this.getIdName(parentId, this.firstBaseDepartmentList),
            twoLevelDepartmentName: '',
            threeLevelDepartmentName: '',
            oneLevelDepartmentId: parentId,
            twoLevelDepartmentId: '',
            threeLevelDepartmentId: ''
        })
        this.$emit("hospital",this.baseInfo)
    },
    getDepartmentListTwoChildren(parentId) {
        this.baseInfo = Object.assign({}, this.baseInfo, {
            twoLevelDepartmentName: this.getIdName(parentId, this.secondBaseDepartmentList),
            threeLevelDepartmentName: '',
            twoLevelDepartmentId: parentId,
            threeLevelDepartmentId: ''
        })
        this.$emit("hospital",this.baseInfo)
    },
    getDepartmentListThreeChildren(parentId) {
        this.baseInfo = Object.assign({}, this.baseInfo, {
            threeLevelDepartmentName: this.getIdName(parentId, this.thirdBaseDepartmentList),
            threeLevelDepartmentId: parentId
        })
        this.$emit("hospital",this.baseInfo)
    },
    onSelectJobTitle(id) {
      this.baseInfo = Object.assign({}, this.baseInfo, {
            jobTitleId: id,
            titleName: this.getIdName(id,this.jobTitleList,'label')
        })
      this.$emit("hospital",this.baseInfo)
    },
    onSelectCertify(val, key) {
      this.baseInfo = Object.assign({}, this.baseInfo, {
            [key]: val,
        })
      this.$emit("hospital",this.baseInfo)
    },
    onSelectBindStatus(representDoctorStatus) {
      this.baseInfo = Object.assign({}, this.baseInfo, {
        representDoctorStatus: representDoctorStatus,
        })
      this.$emit("hospital",this.baseInfo)
    },
    onSelectOrg(orgId) {
        if (orgId) {
            this.orgId = orgId
            this.orgId = this.orgId[this.orgId.length - 1]
        }
      this.baseInfo = Object.assign({}, this.baseInfo, {
          orgId: this.orgId,
        })
      this.$emit("hospital",this.baseInfo)
    },
  }
}
</script>

<style lang="scss" scoped>
.pdg-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
